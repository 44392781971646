import { Search, Tabs, Tab, Tag, Overlay, List, Icon, Cell, NoticeBar, Image, Grid, GridItem } from 'vant'
import Tabbar from '../common/Tabbar.vue'
import Login from '../../intercept/Login.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
import LogVisit from '../../common/LogVisit.vue'
import Clue from '../../common/Clue.vue'
import Call from '../../common/Call.vue'
import '../../../../assets/icon/font-icon.js'
export default {
    components: {
        Tabbar: Tabbar,
        Login: Login,
        Share: Share,
        Loading: Loading,
        LogVisit: LogVisit,
        Clue: Clue,
        Call: Call,
        [Search.name]: Search,
        [Tabs.name]: Tabs,
        [Tab.name]: Tab,
        [Tag.name]: Tag,
        [Overlay.name]: Overlay,
        [List.name]: List,
        [Cell.name]: Cell,
        [Icon.name]: Icon,
        [NoticeBar.name]: NoticeBar,
        [Image.name]: Image,
        [Grid.name]: Grid,
        [GridItem.name]: GridItem
    },
    data () {
        return {
            platform: '',
            sharer: '',
            keyword: '',
            page: { current: 0, size: 10, last: 0 },
            condition: { roName: '', roInstIndustry: '', roInstNature: '', roInstType: '', roInstScale: '', roType: '' },
            operator: { name: '', address: '', linkPerson: '', linkPhone: '', linkWechat: '', type: '' },
            loadingShow: false,
            wechatShow: false,
            guideShow: false,
            finished: false,
            classifyList: [],
            code: '',
            name: '',
            taskActive: 'all',
            taskList: [],
            rcmdTasks: [],
            person: '',
            loginState: 'N',
            studentState: 'N'
        }
    },
    mounted () {
        document.title = '工作招聘'
        this.$refs.login.validate()
        this.createVistClue()
    },
    methods: {
        init () {
            this.platform = window.sessionStorage.getItem(this.SESSION_PLATFORM)
            this.$refs.logVisit.createLog()
            var token = window.sessionStorage.getItem(this.SESSION_TOKEN)
            if (token !== undefined && token !== '' && token !== null) {
                var guide = window.sessionStorage.getItem('guide_show')
                if (guide !== 'N') {
                    // this.guideShow = true
                    // window.sessionStorage.setItem('guide_show', 'N')
                }
                this.retrieveClassifys()
                this.retrieveTaskHotList()
                this.initShare()
            }
        },
        async retrieveClassifys () {
            var pd = { upperCode: '0' }
            const { data: res } = await this.$http.post(this.BMS_URL + '/recruit/classify/retrieveClassifyList', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.classifyList = res.data
            }
        },
        initShare () {
            var title = '好工作不求人，就上微职客'
            var desc = '国企、央企和事业单位等多种类型的多个职位任您选择'
            var link = 'https://moc.utopanet.com/recruit/home?f=1'
            var logo = 'https://moc.utopanet.com/static/logo.png'
            this.$refs.share.initParam(title, desc, link, logo)
        },
        async retrieveTaskHotList () {
            if (!this.finished) {
                this.loadingShow = true
                this.page.current = this.page.current + 1
                var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
                var pd = { operatorCode: operator, current: this.page.current, size: this.page.size }
                var { data: res } = await this.$http.post(this.BMS_URL + '/recruit/taskAPC/retrieveTaskList', this.$qs.stringify(pd))
                if (res.status === '200') {
                    this.taskList = this.taskList.concat(res.data)
                    this.page.last = res.page.last
                    if (res.data.length <= 0) {
                        this.finished = true
                    }
                }
                this.loadingShow = false
            }
        },
        allTask () {
            this.$router.push({ path: '/mde/recruit/search' })
        },
        classifySearch (code) {
            this.$router.push({ path: '/mde/recruit/search', query: { classifyCode: code } })
        },
        setting () {
            this.$router.push({ path: '/mde/person/resumeEdit', query: { inviterCode: this.sharer } })
        },
        consult (code) {
            window.sessionStorage.setItem(this.INFORM_CONSULT_INDEX_BACK, window.location.href)
            this.$router.push({ path: '/mde/recruit/consult', query: { taskCode: code, module: 'USR' } })
        },
        bind () {
            this.$router.push({ path: '/mde/bind' })
        },
        search () {
            this.$router.push({ path: '/mde/recruit/search', query: { keyword: this.keyword } })
        },
        createVistClue () {
            var param = { url: window.location.href }
            var content = '岗位招聘首页访问'
            this.$refs.clue.createClue('RECRUIT_VIST_HOME', '0', JSON.stringify(param), content)
        },
        back () {
            var back = window.sessionStorage.getItem(window.const.global.RECRUIT_HOME_INDEX_BACK)
            if (back !== undefined && back !== null && back !== '') {
                window.location.href = back
            } else {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '已返回到最上层'
                })
            }
        }
    }
}
