<template>
  <van-row>
    <van-col span="24">
      <van-tabbar
        v-model="activeTab"
        :active-color="COLOR_M"
        inactive-color="#000"
      >
        <!-- <van-tabbar-item
          name="home"
          v-if="activeTab === 'home'"
          icon="home-o"
          @click="gather()"
          >首页</van-tabbar-item
        > -->
        <van-tabbar-item name="home" icon="home-o" @click="home()"
          >工作首页</van-tabbar-item
        >
        <van-tabbar-item name="member" icon="friends-o" @click="member()"
          >简历管理</van-tabbar-item
        >
        <van-tabbar-item name="consult" icon="phone-circle-o" @click="consult()"
          >咨询服务</van-tabbar-item
        >
        <van-tabbar-item name="own" icon="user-circle-o" @click="own()"
          >个人中心</van-tabbar-item
        >
      </van-tabbar>
    </van-col>
  </van-row>
</template>
<script>
export default {
  data () {
    var at = this.active
    return {
      activeTab: at
    }
  },
  props: { active: { type: String } },
  mounted () {
  },
  methods: {
    home () {
      this.$router.push({ path: '/mde/recruit/home' })
    },
    gather () {
      this.$router.push({ path: '/mde/main/home' })
    },
    member () {
      var key = window.const.global.MEMBER_PERSON_INDEX_BACK
      window.sessionStorage.setItem(key, window.location.href)
      this.$router.push({ path: '/mde/member/person', query: { profile: 'RECRUIT_NORMAL' } })
    },
    consult () {
      this.$router.push({ path: '/mde/main/consult' })
    },
    own () {
      this.$router.push({ path: '/mde/recruit/own' })
    }
  }
}
</script>
<style lang="less" scoped>
</style>
