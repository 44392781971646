<template>
  <div></div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  props: {
  },
  mounted () {
  },
  methods: {
    async createClue (model, extraCode, param, content) {
      var pd = {
        model: model,
        extraCode: extraCode,
        param: param,
        content: content
      }
      const { data: res } = await this.$http.post(this.BMS_URL + '/clue/pool/createClue', this.$qs.stringify(pd))
      if (res.status !== '200') {
        console.log(res.msg)
      }
    }
  }
}
</script>
