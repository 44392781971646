<template>
  <div class="page">
    <Login ref="login" :callback="init" mode="GST" />
    <div class="search">
      <van-row>
        <van-col span="3" @click="back()"
          ><div class="back-btn">
            <van-icon name="arrow-left" />返回
          </div></van-col
        >
        <van-col span="21">
          <van-search
            v-model="keyword"
            placeholder="请输入搜索关键词"
            ref="keyword"
            show-action
            ><template #action>
              <div @click="search">搜索</div>
            </template></van-search
          >
        </van-col>
      </van-row>
    </div>
    <div class="classify">
      <van-grid>
        <van-grid-item
          :text="classify.name"
          v-for="classify in classifyList"
          :key="classify.code"
          @click="classifySearch(classify.code)"
        >
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use :xlink:href="classify.icon"></use>
            </svg>
          </template>
        </van-grid-item>
      </van-grid>
    </div>
    <van-row class="title">
      <van-col span="12" class="text"
        ><van-icon name="notes-o" /> 热门职位</van-col
      >
      <van-col span="12" class="btn color-m"
        ><div @click="allTask()">职位筛选 <van-icon name="arrow" /></div
      ></van-col>
    </van-row>

    <van-list
      :finished="finished"
      finished-text="没有更多了"
      offset="0"
      class="task-list"
      :immediate-check="false"
      @load="retrieveTaskHotList"
    >
      <template #default>
        <van-row
          class="task bdc-b1s-gray"
          v-for="task in taskList"
          :key="task.code"
          @click="consult(task.code)"
        >
          <van-col span="24" class="content">
            <van-row>
              <van-col span="24" class="name text-short">{{
                task.name
              }}</van-col>
            </van-row>
            <van-row>
              <van-col span="17" class="label">
                <span
                  v-for="item in task.label"
                  :key="item.name"
                  class="item bgc-gray"
                  >{{ item.name }}</span
                >
              </van-col>
              <van-col
                span="7"
                class="salary fc-cyan"
                v-if="task.salaryType === 'DAY'"
                >{{ task.salaryMin.toFixed(1) }} -
                {{ task.salaryMax.toFixed(1) }}元/天</van-col
              >
              <van-col
                span="7"
                class="salary fc-cyan"
                v-if="task.salaryType === 'MOT'"
                >{{ (task.salaryMin / 1000).toFixed(1) }} -
                {{ (task.salaryMax / 1000).toFixed(1) }}K/月</van-col
              >
              <van-col
                span="7"
                class="salary fc-cyan"
                v-if="task.salaryType === 'YER'"
                >{{ (task.salaryMin / 10000).toFixed(1) }} -
                {{ (task.salaryMax / 10000).toFixed(1) }}万/年</van-col
              >
            </van-row>
            <van-row>
              <van-col span="17" class="time"
                >日期：{{ task.timeStart }}到{{ task.timeEnd }}</van-col
              >
              <van-col span="7" class="amount"
                >人数:{{ task.amount }}人</van-col
              >
            </van-row>
          </van-col>
        </van-row>
        <van-row v-if="taskList.length <= 0">
          <van-col span="24" class="no-record">
            <van-image
              width="103"
              height="103"
              :src="require('../../../../assets/images/home/no-record.png')"
            ></van-image>
          </van-col>
        </van-row>
      </template>
    </van-list>
    <van-overlay :show="guideShow">
      <div class="wrapper" @click="guideShow = false">
        <div class="guide">
          <van-image
            class="img"
            :src="require('../../../../assets/images/guide.jpg')"
          ></van-image>
          <van-button
            plain
            :color="COLOR_M"
            size="mini"
            class="btn"
            icon="info-o"
            @click="guideShow = false"
            >我了解了</van-button
          >
        </div>
      </div>
    </van-overlay>
    <Clue ref="clue" />
    <Call
      ref="call"
      win-show="false"
      entry-show="true"
      clue-model="RECRUIT_CONSULT_ONLINE"
    />
    <Loading :show="loadingShow" />
    <Share ref="share" />
    <LogVisit ref="logVisit" module-type="RCT" module-label="RCT_HOME_INDEX" />
    <Tabbar active="home" v-if="platform === 'WPP'" />
    <!-- <Tabbar mode="enroll" active="home" /> -->
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
